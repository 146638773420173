<template>
  <v-menu
    v-model="menu"
    offset-x
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn
          v-bind="attrs"
          icon
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-list>
      <SettingsDialog
        :readonly="readonly"
        :file-node="fileNode"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            v-bind="attrs"
            link
            v-on="on"
            @mouseup="close"
          >
            <v-list-item-title>{{ $t('page.files.file_settings_title') }}</v-list-item-title>
          </v-list-item>
        </template>
      </SettingsDialog>
    </v-list>
  </v-menu>
</template>

<script>
import SettingsDialog from './Settings/SettingsDialog'

import { FileNode } from '@/models'

export default {
  name: 'FileMenu',

  components: {
    SettingsDialog
  },

  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    fileNode: {
      type: FileNode,
      default: null
    }
  },

  data: () => ({
    menu: false
  }),

  methods: {
    close() {
      this.menu = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
